<template>
  <AwlCard v-bind="{...$attrs, ...$props}" class="awl-modal-card" :border="border" box-shadow>
    <template #header v-if="$scopedSlots.header">
      <slot name="header"></slot>
    </template>
    <template #title v-if="$scopedSlots.title || title">
      <slot name="title">{{ title }}</slot>
    </template>
    <slot></slot>
  </AwlCard>
</template>

<script>

import AwlCard from './AwlCard.vue';

export default {
  components: {
    AwlCard,
  },
  props: {
    border: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    title () {
      return this?.$scopedSlots?.title || this?.$attrs?.title || this?.$props?.title;
    },
  },
};

</script>

<style lang="scss">

.awl-modal-card {
  max-width: 325px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up("sm") {
    max-width: 450px;
  }

  @include media-breakpoint-up("md") {
    max-width: 550px;
  }

  &.awl-card .awl-card--header {
    padding-bottom: 20px;
  }

  &.awl-card .awl-card--content {
    padding-top: 0;
  }
}

</style>
