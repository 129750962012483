<template>
  <main class="page-password-reset d-flex align-items-center justify-content-center">
    <AwlModalCard title="Reset Password">
      <PasswordResetForm />
    </AwlModalCard>
  </main>
</template>

<script>

import AwlModalCard from '@/views/components/Card/AwlModalCard.vue';
import PasswordResetForm from '@/views/components/Account/PasswordResetForm.vue';

export default {
  components: {
    AwlModalCard,
    PasswordResetForm,
  },
};

</script>

<style lang="scss">

.page-password-reset {
  background-image: url($path-images + '/login-background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    background: rgb(0,0,0);
    background: linear-gradient(0deg,rgba(0,0,0,.8),rgba($primary,.1));
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

</style>
