<template>
  <main class="page-password-change py-5">
    <AwlCard title="Recupera password">
      <ResetPasswordChangeForm class="card-body" />
    </AwlCard>
  </main>
</template>

<script>

import AwlCard from '@/views/components/Card/AwlModalCard.vue';
import ResetPasswordChangeForm from '@/views/components/Account/ResetPasswordChangeForm.vue';

export default {
  components: {
    AwlCard,
    ResetPasswordChangeForm,
  },
};

</script>
