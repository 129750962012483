<template>
  <FormContainer class="login-form" @submitForm="login">
    <Alert v-if="error" status="danger">
      <template v-if="error === 'login'">Email o password non corrette, riprovare.</template>
      <template v-else>Errore di sistema, riprovare più tardi.</template>
    </Alert>
    <div class="row">
      <FormInputTextWidget
        class="col-12"
        label="Email"
        placeholder="example@email.com"
        v-model="user.email"
        :errorMsg="errorHash.email"
        type="email"
        @input="removeError('email')"
      />

      <FormInputTextWidget
        class="col-12"
        label="Password"
        v-model="user.password"
        :errorMsg="errorHash.password"
        type="password"
        @input="removeError('password')"
      />

      <div class="col-12">
        <div class="row mt-2">
          <div class="mb-4 col-12 d-md-none">
            <button class="btn btn-primary" type="submit">
              Accedi
            </button>
          </div>
          <div class="col-12 col-md-6">
            <router-link :to="{ name: 'password-reset', params: { email: user.email || null } }">
              Password Dimenticata?
            </router-link>
          </div>
          <div v-if="!disableSignup" class="col-12 col-md-6 mt-4 mt-md-0 text-md-right">
            <router-link :to="{ name: 'sign-up' }">
              Non hai un account? Registrati
            </router-link>
          </div>
        </div>
      <div class="row">
          <div class="mt-4 col-md d-none d-md-block text-right">
            <button class="btn btn-primary" type="submit" :disabled="doingLogin">
              Accedi
            </button>
          </div>
        </div>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';
import { emailIsValid } from '@/libs/Form/helpers/validation.js';

import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';
import Alert from '@/libs/Feedback/components/Alert.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormInputTextWidget,
    FormContainer,
    Alert,
  },
  props: {
    disableSignup: {
      type: Boolean,
      default: false,
    },
    doingLogin: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      error: null,
      user: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    login () {
      if (this.doingLogin) {
        return;
      }

      this.loginStarted();
      this.clearFeedback();

      if (!this.isValid()) {
        this.loginEnded();

        return;
      }

      this.$oauth2
        .loginWithCredentials(this.user.email, this.user.password)
        .then(() => this.$router.push({ name: 'home' }))
        .catch(error => {
          if (error?.response?.status === 400) {
            this.error = 'login';

            return;
          }

          this.error = 'service';
        })
        .finally(this.loginEnded)
      ;
    },
    isValid () {
      if (!this.user.email) {
        this.addError('Inserire l\'email.', 'email');
      } else if (!emailIsValid(this.user.email)) {
        this.addError('Email non valida.', 'email');
      }

      if (!this.user.password) {
        this.addError('Inserire la password.', 'password');
      }

      return !this.hasErrors;
    },
    clearFeedback () {
      this.error = null;
      this.clearErrors();
    },
    loginStarted () {
      this.$emit('login-started');
      this.$emit('update:doing-login', true);
    },
    loginEnded () {
      this.$emit('update:doing-login', false);
    },
  },
};

</script>

<style lang="scss">

$signup-link-text-color: red !default;

.login-form {
  .signup-link {
    color: $signup-link-text-color;
  }
}

</style>
