<template>
  <div class="form-errors"><slot /></div>
</template>

<script>

export default {};

</script>

<style lang="scss">

$form-error-color: red !default;

.form-errors {
  color: $form-error-color;
  font-weight: bold;
}

</style>
